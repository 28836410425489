<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <asset-condition-form
      v-if="assetCondition.id"
      ref="asset-condition-form"
      :is-edit="isEdit"
    />
    <div v-else>
      <div v-if="!error">
        <b-row class="mt-2">
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="checkbox" />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <component-not-found
          title="Pricing List"
          :button-link="{ name: 'settings-catalog-price-list'}"
        />
      </div>
    </div>
  </div>

</template>
<script>
import {
  BRow, BCol, BSkeleton, VBTooltip,
} from 'bootstrap-vue'
import BrandForm from '@/views/settings/catalog/brands/components/BrandsForm.vue'
import store from '@/store'
import config from '../assetConditionConfig'
import AssetConditionForm from '../components/AssetConditionForm.vue'

export default {
  name: 'AssetConditionUpdate',
  components: {
    BrandForm,
    AssetConditionForm,
    BRow,
    BCol,
    BSkeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['asset-condition-form']) this.$refs['asset-condition-form'].showConfirmationModal(next)
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    assetCondition() {
      return this.$store.state[this.MODULE_NAME].assetConditionForm
    },
  },
  async created() {
    await this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const assetCondition = this.mappingFields(Object.keys(this.assetConditionFields), data, {
        id: data.id,
        name: data.name,
        active: data.active,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, assetCondition)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_ASSET_CONDITION_FORM_CLONE`, assetCondition)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'asset-condition-taxonomies'
    const MODULE_NAME_CLONE = 'cloneData'
    const { assetConditionFields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      assetConditionFields,
    }
  },
}
</script>
