<template>
  <div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ isUpdate ? $t('Update a Brand') : $t('Add a Brand') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <div>
      <validation-observer
        ref="brandCreateVal"
      >
        <b-row>
          <b-col
            md="6"
          >
            <component
              :is="fields[field].type"
              v-for="field in ['name']"
              :key="field"
              v-model="brand[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
              @keyup.enter="submit"
            />
          </b-col>
          <b-col
            md="6"
          >
            <label class="inputLabel"> {{ $t('Status') }}</label>
            <component
              :is="fields[field].type"
              v-for="field in ['is_active']"
              :key="field"
              v-model="brand[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings-catalog-brand-list' })"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </div>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>

import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import config from '../brandConfig'

export default {
  name: 'BrandForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  inject: ['brandModuleName'],
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      onSubmit: false,
    }
  },
  computed: {
    brand() {
      return this.$store.state[this.brandModuleName].brandForm
    },
    brandClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingBrand
    },
    isFormChanged() {
      return JSON.stringify(this.brand) !== this.brandClone
    },
    isUpdate() {
      return this.brand.id
    },
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.brandCreateVal.reset()
      const smth = JSON.parse(this.brandClone)
      this.$store.commit('brand/SET_FORM', smth)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.brandCreateVal.validate().then(success => {
        if (success) {
          const data = this.brand
          this.sendNotification(this, data, `${this.brandModuleName}/${this.isUpdate ? 'update' : 'create'}`)
            .then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.brandCreateVal)
        }
      })
    },

    clear() {
      this.$refs.brandCreateVal.reset()
      this.$router.push({ name: 'settings-catalog-brand-list' })
    },
    getProps(fieldName) {
      const data = {
        field: this.fields[fieldName],
        name: fieldName,
      }
      if (fieldName === 'state_id') {
        data.parentValue = this.brand.country_id?.id
      }
      return data
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>

</style>
