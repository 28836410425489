import {
  CHECKBOX_LEF, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'brand'
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '10%' } },
    { key: 'name', label: 'Name', thStyle: { width: '20%' } },
    { key: 'assets', label: 'Associated items', thStyle: { width: '15%', textAlign: 'center' } },
    { key: 'id', label: 'ID', thStyle: { width: '30%', textAlign: 'center' } },
    { key: 'is_active', label: 'Status', thStyle: { width: '20%' } },
  ]
  const productTableColumn = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'model',
      label: 'Model',
    },
    {
      key: 'category',
      label: 'Category',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ]
  const fields = {
    name: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
    },
  }
  const brandInformationColumns = [
    { key: 'name', label: 'Name' },
    { key: 'is_active', label: 'Status' },
    { key: 'products_count', label: 'Product Count' },
  ]
  return {
    MODULE_NAME,
    tableColumns,
    brandInformationColumns,
    productTableColumn,
    fields,
  }
}
